<template>
  <div>
    <h1 class="p-5 text-center">Logging you out..</h1>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { logout } from "../api/Auth.js";

export default {
  name: "Logout",
  mounted: function () {
    let app = this;
    logout().finally(() => {
      app.logout();
      this.$router.push({ name: "Login" });
    });
  },
  methods: {
    ...mapActions({
      logout: "logout"
    })
  }
};
</script>
